import React from 'react';
import logo from '../assets/images/logo.png'; // Utilisation d'import pour l'image du logo

function Topbar() {
    return (
        <header className="main-header header-style-three d-none d-md-block">
            {/* Header Top */}
            <div className="header-top-two">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="top-left">
                            {/* Vous pouvez ajouter du contenu ici */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Header Top */}
            
            {/* Header Lower */}
            <div className="header-lower">
                <div className="auto-container">
                    {/* Main Box */}
                    <div className="main-box">
                     
                        <div className="nav-outer">
                            {/* Main Menu */}
                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-header">
                                    {/* Toggle Button */}
                                    <button 
                                        className="navbar-toggler" 
                                        type="button" 
                                        data-toggle="collapse" 
                                        data-target="#navbarSupportedContent" 
                                        aria-controls="navbarSupportedContent" 
                                        aria-expanded="false" 
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix float-right">
                                        <li className="current"><a href="/">Home</a></li>
                                        <li><a href="/commite">Committees</a></li>
                                        <li><a href="/authors">Authors Guidelines</a></li>
                                        <li><a href="/register">Registration</a></li>
                                        <li><a href="/travel">Travel</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Topbar;
