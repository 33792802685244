import React from 'react'
import Navbar from '../Layouts/Navbar'
import BarMobile from '../Layouts/BarMobile'

function Contact() {
    return (

        <div>
            <Navbar />
            <BarMobile />
            <section className="page-title" style={{ backgroundImage: 'url(images/background/11.jpg)' }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye" />
                    <span className="icon icon-dotted-circle" />
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Contact</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Conference</a></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <section className="contact-section">
                <div className="auto-container">
                    <div className="sec-title text-center">

                        <h2> Contact Us  </h2>
                        <h4>Find your answer online or by connecting with us</h4>
                        <span className="divider" />
                    </div>
                    <div className="contact-form" style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', border: '1px solid #ddd', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
    <form method="post" action="sendemail.php" id="contact-form">
        <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{ marginBottom: '15px' }}>
                <div className="input-outer">
                    <input type="text" name="username" placeholder="Name" required style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <span className="icon fa fa-user" style={{ position: 'absolute', right: '10px', top: '10px' }} />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{ marginBottom: '15px' }}>
                <div className="input-outer">
                    <input type="email" name="email" placeholder="Email" required style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <span className="icon fa fa-envelope" style={{ position: 'absolute', right: '10px', top: '10px' }} />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{ marginBottom: '15px' }}>
                <div className="input-outer">
                    <input type="text" name="phone" placeholder="Phone" required style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <span className="icon fa fa-phone" style={{ position: 'absolute', right: '10px', top: '10px' }} />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{ marginBottom: '15px' }}>
                <div className="input-outer">
                    <input type="text" name="subject" placeholder="Subject" required style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <span className="icon fa fa-pencil-alt" style={{ position: 'absolute', right: '10px', top: '10px' }} />
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group" style={{ marginBottom: '15px' }}>
                <textarea name="message" placeholder="Message" defaultValue={""} style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }} />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group text-center">
                <button className="theme-btn btn-style-one" type="submit" name="submit-form" style={{ padding: '10px 20px', fontSize: '16px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: '#fff' }}>
                    <span className="btn-title">Submit</span>
                </button>
            </div>
        </div>
    </form>
</div>

                </div>
            </section> */}

            <section className="map-section my-5"> 
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="contact-column col-lg-5 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    {/* <span className="sub-title">QUICK CONTACT</span> */}
                                    <h2> Contact Us</h2>
                                    <span className="divider" />
                                    {/* <div className="text">Available to our business customers 24 hours a day.</div> */}
                                </div>
                                <ul className="contact-info">
                                    <li>
                                        <span className="icon fa fa-map" />
                                        <h4>Our Location:</h4>
                                        <p> Valenciennes , France</p>
                                    </li>
                                    {/* <li>
                                        <span className="icon fa-solid fa-phone" />
                                        <h4>24/7 Hotline:</h4>
                                        <p>+33 0000 0000</p>
                                    </li>  */}
                                    <li>
                                        <span className="icon fa fa-envelope" />
                                        <h4>Email:</h4>
                                        <p><a href="mailto:newyork@example.com">icmaie.conf@gmail.com

                                        </a></p>
                                    </li>
                                </ul>
                            </div> 
                        </div>
                        {/* Form Column */}
                        <div className="map-column col-lg-7 col-md-12 col-sm-12">
                            {/*Map Outer*/}
                            <div className="map-outer">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2547.560757773491!2d3.510496476089737!3d50.31878859683558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2ed2777ed25f3%3A0x9e55f3861a5ef7bb!2sInstitut%20des%20Mobilit%C3%A9s%20et%20des%20Transports%20Durables!5e0!3m2!1sfr!2sfr!4v1717669188828!5m2!1sfr!2sfr" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade">
</iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
      
        </div>

    )
}

export default Contact