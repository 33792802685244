import React from 'react'
import Navbar from '../Layouts/Navbar'
import BarMobile from '../Layouts/BarMobile'

function Authors() {
    return (

        <div>
            <Navbar />
            <BarMobile />
            <section className="page-title" style={{ backgroundImage: 'url(images/background/11.jpg)' }}>
                <div className="anim-icons full-width">

                    <span className="icon icon-dotted-circle" />
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Authors guidelines</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Conference</a></li>
                            <li> Authors Guidelines</li>
                        </ul>
                    </div>  
                </div>
            </section>

            <div>


                {/*End Page Title*/}
                <section className="feature-section-two">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-5 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <h2>Author Guideline </h2>
                                        <span className="sub-title">Initial Paper Submission</span>
                                        <p>Authors are required to follow the Springer Formatting Guidelines and use Word format only.  A sample paper that could be used as a template can be found here:  <a href="/ICMAIE2025_sample_paper.pdf" download>ICMAIE2025_sample_paper</a>. Please note that Springer can only accept papers in Word format for the ICMAIE 2025 proceedings volume, NOT in LaTeX.</p>
                                        <p>Authors should submit papers using the Easychair conference management system at :
    <a href="https://easychair.org/conferences/?conf=icmaie2025"> Easychair link </a>.
</p>
                                        <p>Submitted papers will be subject to a peer review process. Complete papers will be required for the review process and abstracts alone will not be sufficient. The expected types of submissions include the following : <br />
                                           <b>Full paper:</b>  12 pages, <br />
                                           <b>  Short paper: </b> 6 pages, <br />
                                           <b>  Workshop paper:</b> 6 pages, <br />
                                           <b>  Poster:</b> 4 pages.
                                        </p>
                                    </div> 

                                </div>
                            </div>
                            <div className="features-column col-lg-7 col-md-12 col-sm-12 ">
                                {/*Feature Block*/}
                                <div className="feature-block-two wow fadeInUp animated w-100" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                                    <div className="inner-box">

                                        <h4>Note</h4> 
                                        <p>Note: The conference employs a double-blind review process, hence authors’ names and affiliations should NOT be present within the manuscript. Authors should include their names only in the appropriate fields in the paper submission form on Easychair.
                                        </p>
                                    </div>

                                </div>
                             
                                <div className="feature-block-three wow fadeInUp animated" data-wow-delay="600ms" style={{ visibility: 'visible', animationDelay: '600ms', animationName: 'fadeInUp' }}>
                                    <div className="inner-box">

                                        <p>Authors’ information should be included ONLY in the camera-ready version of the manuscript and NOT in the initial version
                                        However, during the preparation of the initial manuscript, authors’ should leave a number of empty lines at the beginning of the manuscript, to include authors’ information during the camera-ready manuscript submission.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>





    )
}

export default Authors