import React, { useState } from 'react';
import logo from '../assets/images/logo.png'; // Utilisation d'import pour l'image du logo
import { useLocation } from 'react-router-dom';
function BarMobile() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
 
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary d-block d-md-none mb-0">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                {/* <a className="navbar-brand" href="#">Navbar</a> */}
                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className={location.pathname === '/' ? 'current' : ''}><a href="/">Home</a></li>
                                        <li className={location.pathname === '/commite' ? 'current' : ''}><a href="/commite">Committees</a></li>
                                        <li className={location.pathname === '/authors' ? 'current' : ''}><a href="/authors">Authors guidelines</a></li>
                                        <li className={location.pathname === '/register' ? 'current' : ''}><a href="/register">Registration</a></li>
                                        <li className={location.pathname === '/travel' ? 'current' : ''}><a href="/travel">Travel</a></li>
                                        <li className={location.pathname === '/contact' ? 'current' : ''}><a href="/contact">Contact</a></li>
                    </ul>

                </div>
            </div>
        </nav>
    );
}

export default BarMobile;
