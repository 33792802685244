import React from 'react'
import Navbar from '../Layouts/Navbar'
import BarMobile from '../Layouts/BarMobile'

function Registration() {

    return (

        <>


            <div>
                <Navbar />
                <BarMobile />
                <section className="page-title" style={{ backgroundImage: 'url(images/background/11.jpg)' }}>
                    <div className="anim-icons full-width">

                        <span className="icon icon-dotted-circle" />
                    </div>
                    <div className="auto-container">
                        <div className="title-outer">
                            <h1>Registration fee</h1>
                            <ul className="page-breadcrumb">
                                <li><a href="/">Conference</a></li>
                                <li> Registration fee </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="pricing-section-three">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                           
                            <h5>Author registration include: access to the conference venue, 2 daily coffee breaks, 1 ticket for the banquet, presentation and publication of one paper (the registrant must be a co-author of the associated paper).</h5>
                            <span className="divider" />
                        </div>

                        <div className="row">
                            {/* Pricing block */}
                            <div className="pricing-block-three col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms" style={{ visibility: 'visible', animationDelay: '400ms', animationName: 'fadeInUp' }}>
                                <div className="inner-box">
                                    <div className="title-box">
                                        <h3>Student</h3>
                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup>€<em></em></sup>300</div>
                                    </div>
                                    <div className="btn-box">
                                        {/* <button className="theme-btn btn-style-two"><span className="btn-title">Buy
                                            Ticket</span></button> */}
                                    </div>
                                </div>
                            </div>
                            {/* Pricing block */}
                            <div className="pricing-block-three tagged col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                                <div className="inner-box">
                                    <div className="title-box">
                                        <h3>Non-student</h3>
                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup>€<em></em></sup>350</div>
                                    </div>
                                    <div className="btn-box">
                                        {/* <button className="theme-btn btn-style-one"><span className="btn-title">Buy
                                            Ticket</span></button> */}
                                    </div>
                                </div>
                            </div>
                            {/* Pricing block */}
                            <div className="pricing-block-three col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms" style={{ visibility: 'visible', animationDelay: '400ms', animationName: 'fadeInUp' }}>
                                <div className="inner-box">
                                    <div className="title-box">
                                        <h3>Industry</h3>
                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup>€<em></em></sup>450</div>
                                    </div>
                                    <div className="btn-box">
                                        {/* <button className="theme-btn btn-style-two"><span className="btn-title">Buy
                                            Ticket</span></button> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
{/* 
                <section className="call-to-action-two mb-5" style={{ backgroundImage: 'url(images/icons/5.png)' }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-6 offset-lg-6">
                                <div className="content-box wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                                    <div className="form-column column col-lg-12 col-md-12 col-sm-12">
                                        <div className="sec-title">
                                            <h2>Register Here</h2>
                                            <div className="separate" />
                                        </div>

                                        <div className="styled-form register-form">
                                            <form method="post" action="index.html">
                                                <div className="form-group">
                                                    <span className="adon-icon"><span className="fa fa-user" /></span>
                                                    <input type="text" name="username" defaultValue placeholder="Your Name *" />
                                                </div>
                                                <div className="form-group">
                                                    <span className="adon-icon"><span className="fa fa-envelope" /></span>
                                                    <input type="email" name="useremil" defaultValue placeholder="Emai Address*" />
                                                </div>
                                                <div className="form-group">
                                                    <span className="adon-icon"><span className="fa fa-unlock-alt" /></span>
                                                    <input type="password" name="userpassword" defaultValue placeholder="Enter Password" />
                                                </div>
                                                <div className="clearfix">
                                                    <div className="form-group pull-left">
                                                        <button type="button" className="theme-btn btn-style-one"><span className="btn-title">Register
                                                            here</span></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>












        </>

    )
}

export default Registration