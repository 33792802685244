import React from 'react'
import Navbar from '../Layouts/Navbar'
import BarMobile from '../Layouts/BarMobile'

function Commite() {
    return (

        <div className="page-wrapper">
            <Navbar />
            <BarMobile />
            <section className="page-title" style={{ backgroundImage: 'url(images/background/11.jpg)' }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye" />
                    <span className="icon icon-dotted-circle" />
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Conference committees</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Conference</a></li>
                            <li>committees</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="schedule-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        {/* <span class="sub-title">Thought leadership</span> */}
                        <h2>Conference Founder</h2>
                        <span className="divider" />
                    </div>
                    <div className="schedule-tabs tabs-box">
                        <div className="tabs-content">
                            {/*Tab*/}
                            <div className="tab active-tab" id="tab-1">
                                <div className="schedule-timeline">
                                    {/* schedule Block */}
                                    <div className="schedule-block-three">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c6.jpeg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">ABED Mourad <span className="designation"> </span>
                                                    </h4>
                                                    <h4><a href="https://www.uphf.fr/">Polytechnic University of Hauts-de-France, France</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sec-title text-center">
                                        {/* <span class="sub-title">Thought leadership</span> */}
                                        <h2>General Chairs</h2>
                                        <span className="divider" />
                                    </div>
                                  
                                    {/* schedule Block */}
                                    <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c7.jpg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Abdelhakim ARTIBA <span className="designation">
                                                    </span></h4>
                                                    <h4><a href="https://www.uphf.fr/">Polytechnic University of Hauts-de-France, France</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* schedule Block */}
                                    <div className="schedule-block-three">
                                        <div className="inner-box">
                                            <div className="date">
                                                <span className="time">11.00 am <br /> 12.00 am</span>
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c2.jpeg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name"> Dejian Liu <span className="designation"> </span></h4>
                                                    <h4><a href="https://english.bnu.edu.cn/">Beijing Normal University,China</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/08.jpg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name"> Catherine Demarey  <span className="designation">
                                                    </span></h4>
                                                    <h4><a href="https://lille.catholique.fr/">Lille Catholic University, France </a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* schedule Block */}
                                    
                                    <div className="sec-title text-center">
                                        <h2>General Program Chairs</h2>
                                        <span className="divider" />
                                    </div>
                                    
                                    {/* schedule Block */}
                                    <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c3.jpg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Mouna Denden <span className="designation"> </span>
                                                    </h4>
                                                    <h4><a href="https://www.uphf.fr/">Polytechnic University of Hauts-de-France, France</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* schedule Block */}
                                    <div className="schedule-block-three">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c1.jpg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Ahmed Tlili<span className="designation"> </span></h4>
                                                    <h4><a href="https://english.bnu.edu.cn/">Beijing Normal University,China</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   {/* schedule Block */}
                                   <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c9.png" alt /> 
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Houneida Sakly<span className="designation"> </span>
                                                    </h4> 
                                                    <h4> <a href=''>CRMN-Technopole of Sousse "Novation City",Tunisia</a>  </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                   
                                    {/* schedule Block */}
                                    <div className="schedule-block-three">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/9.jpg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Charles Yaacoub<span className="designation"> </span></h4>
                                                    <h4> <a href='https://lille.catholique.fr/'>Lille Catholic University, France </a>  </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                  
                                     {/* schedule Block */}
                                 
                                    {/*workshop chairs */}
                                    <div className="sec-title text-center">
                                        <h2>Workshop Chairs</h2>
                                        <span className="divider" />
                                    </div>

                                    {/* schedule Block */}
                                    <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c5.jpeg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">Mestiri Makram <span className="designation"> </span>
                                                    </h4>
                                                    <h4><a href="https://www.uphf.fr/">Polytechnic University of Hauts-de-France, France</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-block-three">
                                        <div className="inner-box">
                                            <div className="date">
                                                <span className="time">08.00 am <br /> 10.00 am</span>
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/c4.jpeg" alt />
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name"> TingWen Chang <span className="designation"> </span>
                                                    </h4>
                                                    <h4><a href="https://english.bnu.edu.cn/">Beijing Normal University,China</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-block-three even">
                                        <div className="inner-box">
                                            <div className="date">
                                            </div>
                                            <div className="inner">
                                                <figure className="thumb">
                                                    <img src="images/clients/8.jpg" alt /> 
                                                </figure>
                                                <div className="speaker-info">
                                                    <h4 className="name">	Jalal Possik <span className="designation"> </span>
                                                    </h4> 
                                                    <h4> <a href='https://lille.catholique.fr/'>Lille Catholic University, France </a>  </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>


    )
}

export default Commite