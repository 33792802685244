import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button className="scroll-to-top" onClick={scrollToTop} style={{ display: isVisible ? 'block' : 'none', position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}>
           <span className="fa fa-angle-up" />
        </button>
    );
};

export default ScrollToTopButton;

