import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
 

function Navbar() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }; 
 
    return (
        <header className="main-header header-style-two fixed-header d-none d-md-block"> 
            <div className="header-lower">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="logo"><a href="/"><img src="images/logo.png" alt="logo" title="logo" /></a></div>
                        </div>
                        <div className="nav-outer">
                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-header">
                                    <button 
                                        className="navbar-toggler" 
                                        type="button" 
                                        onClick={toggleMenu}
                                    >
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>
                                <div className={`navbar-collapse collapse clearfix ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li className={location.pathname === '/' ? 'current' : ''}><a href="/">Home</a></li>
                                        <li className={location.pathname === '/commite' ? 'current' : ''}><a href="/commite">Committees</a></li>
                                        <li className={location.pathname === '/authors' ? 'current' : ''}><a href="/authors">Authors guidelines</a></li>
                                        <li className={location.pathname === '/register' ? 'current' : ''}><a href="/register">Registration</a></li>
                                        <li className={location.pathname === '/travel' ? 'current' : ''}><a href="/travel">Travel</a></li>
                                        <li className={location.pathname === '/contact' ? 'current' : ''}><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
