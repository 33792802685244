
import Topbar from '../Layouts/Topbar'
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import track1Image from '../assets/images/background/track1.png';
import track2Image from '../assets/images/background/track2.png';
import track3Image from '../assets/images/background/track3.png';
import track4Image from '../assets/images/background/track4.jpg';
import track5Image from '../assets/images/background/track5.png';
import track6Image from '../assets/images/background/track6.png';
import track7Image from '../assets/images/background/track7.png';
import BarMobile from '../Layouts/BarMobile';
function Home() {
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', boldText: '', content: '' });
    const handleClose = () => {
      console.log('Closing modal'); // Ajout de logs
      setShow(false);
    };
  
    const handleShow = (title, boldText, content) => {
        setModalContent({ title, boldText, content });
        setShow(true);
    };
    const tracks = [
      {
        title: 'Track 1',
        boldText: 'Future Education in the metaverse',
        content: `  This track focuses on, but not limited to : <br></br>
          • Pedagogies and educational approaches in the metaverse <br />
          • Technology enhanced science, technology, engineering & Math education (TeSTEM) <br />
          • Collaborative learning in the metaverse <br />
          • Assessment in the metaverse <br />
          • Recommender system in educational metaverse environments <br />
          • Virtual reality, augmented reality, mixed reality, and extended reality applications in education <br />
          • Automatic scalarization in educational <br />
          • Integration of digital twins into curriculum development <br />
          • Industry-academia collaborations in digital twin training initiatives <br />
          •	3D scanning and rendering techniques for creating digital twins <br />
          • Simulation and modeling techniques for educational purposes

        `,
        image: track1Image, // Chemin d'accès à l'image pour Track 2
      },
      {
        title: 'Track 2',
        boldText: 'Security, Sustainability and accessibility in metaverse',
        content: `  This track focuses on, but not limited to : <br></br>
          • Cybersecurity in metaverse <br />
          •	Blockchain and smart contracts in metaverse <br />
          •	NFT security <br />
          •	Assistive technology for students with disabilities <br />
          • Equity, diversity and inclusion in metaverse <br />
          •	Ethical metaverse <br />
          •	Accessibility and usability of metaverse technologies <br />
          •	Cognitive, motivational and affective aspects for personalization <br />
          •	Measurement and evaluation in adaptive metaverse environments <br />
          •	Design of adaptive avatars <br />
          •	Metaverse and society 5.0 <br />
          •	Sustainability in and of metaverse

        `,
        image: track2Image, // Chemin d'accès à l'image pour Track 
      },
      
      {
        title: 'Track 3',
        boldText: 'Human machine interactions and collaborations in metaverse',
        content: ` This track focuses on, but not limited to :<br></br>
        •	Collaborative intelligence (human intelligence and artificial intelligence) in education <br />
        •	Teaming up with the machine in education <br />
        •	Interactions with VR, AR, MR and XR <br />
        •	Brain computer interaction <br />
        •	Somatosensory interaction <br />
        •	Big data and Learning analytics <br />
        •	3D AI Modeling
        
        `,
        image: track4Image, // Chemin d'accès à l'image pour Track 2
      },
      
      
      {
        title: 'Track 4',
        boldText: 'Innovations in Digital Heritage through AI and Blockchain in the metaverse',
        content: `
         This track focuses on, but not limited to : <br></br>
         •	AI and Machine Learning for Cultural Heritage <br />
         •	Blockchain, NFTs, and Digital Provenance in Heritage Preservation <br />
         •	Immersive Digital Heritage: Virtual and Augmented Reality <br />
         •	Motion Capture for Heritage Gesture Reconstruction <br />
         •	NeuroMetaverse: Advancements in Neuroscience, AI, and Digital Heritage <br />
         •	Advanced Visualization Techniques for Heritage in the Metaverse <br />
         •	Intangible heritage protection with NFT <br />
         •	Digitizing Heritage: Scanning Technologies, AI, and the Metaverse
        
        `,
        image: track7Image, // Chemin d'accès à l'image pour Track 2
      },
      // Add more tracks as needed...
    ];
    

    return (


       
          <div>
          

            {/*Main Slider*/}
            <BarMobile />

        <section className="banner-section">
  <div className="slide-item" style={{backgroundImage: 'url("images/main-slider/1.jpg")', backgroundSize: 'cover', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <div className="auto-container">
      <div className="content-box" style={{textAlign: 'center', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
        <h2>First International Conference on Metaverse and AI in Education for a Sustainable Society (ICMAIE 2025)</h2>
        <div>4-6 June Valenciennes, France</div>
        <div>Polytechnic University of Hauts-de-France</div>
      </div>
    </div>
  </div>
</section>

            {/* End Main Slider*/}
            <Topbar />
            {/* About Section */}
            <section className="about-section">
                <div className="auto-container">
                    <div className="row">
                        {/* Image Column */}
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
    <div className="about-image-wrapper">
        <figure className="image-1 wow zoomIn animated" data-wow-delay="300ms" style={{ visibility: 'visible', animationDelay: '300ms', animationName: 'zoomIn' }}>
            <img src="images/resource/vector.png" alt />
        </figure>
    </div>
</div>

                        {/* Content Column */}
                        <div className="content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    {/* <span className="sub-title">WELCOME TO ICMAIE</span> */}
                                    <h2>Call for Papers</h2>
                                    <span className="divider" />
                                </div>
                                <p style={{textAlign: 'justify', color: 'black'}}>
  The International Conference on <strong>Metaverse and AI in Education for a Sustainable Society</strong> aims to bring industry and academia together to discuss, present, and showcase the latest research and development (R&amp;D) about the immersive technology advancement and novel research on the adoption and implementation of the metaverse and artificial intelligence in education. Additionally, the conference seeks to identify emerging research topics and open issues for further investigation and collaboration. Furthermore, the conference underscores the significance of immersive and intelligent technologies for sustainable and inclusive social good, which are vital for tackling the complex challenges confronting our world and fostering a more equitable and environmentally responsible future education. Therefore, this conference offers a valuable opportunity to contribute meaningfully to the development of <strong>Education 5.0</strong> and <strong>Society 5.0</strong>.
</p>



           <p style={{textAlign: 'justify', color: 'black'}}>
  The conference will be hosted by the <strong>Polytechnic University of Hauts-de-France</strong>, Valenciennes, France, on <strong>4-6 June 2025</strong>. It is jointly organized by the <strong>Polytechnic University of Hauts-de-France</strong> and <strong>Beijing Normal University, China</strong>.
  We invite submissions of papers reporting original academic or industrial research in the area of immersive and intelligent technology for <strong>Education 5.0</strong> and <strong>Society 5.0</strong>, according to the following tracks (but not limited to) :
</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

  {/* Feature Section */}

  <section className="feature-section pt-0">
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>Tracks</h2>
      <span className="divider" />
    </div>
    <div>
    <div className="row">
    {tracks.slice(0, 4).map((track, index) => (
       <div
       className="feature-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
       key={index}
       onClick={() => handleShow(track.title, track.boldText, track.content)}
       style={{ visibility: 'visible', animationName: 'fadeInUp', cursor: 'pointer' }}
     >
        <div className="track-container" style={{ position: 'relative' }}>
                <img src={track.image} alt={`Track ${index + 1}`} className="track-image" />
                <div className="text-overlay">
                    <h3>{track.title}</h3>
                    <p>{track.boldText}</p>
                </div>
            </div>
       <div className="inner-box" style={{ padding: '20px', backgroundColor: 'white', borderRadius: '0 0 10px 10px' }}>
         {/* Additional content if needed */}
       </div>
     </div>
    ))}
</div>


<div className="row justify-content-center">
  {tracks.slice(4).map((track, index) => (
    <div
      className="feature-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
      key={index}
      onClick={() => handleShow(track.title, track.boldText, track.content)}
      style={{ visibility: 'visible', animationName: 'fadeInUp', cursor: 'pointer' }}
    >
       <div className="track-container" style={{ position: 'relative' }}>
                <img src={track.image} alt={`Track ${index + 1}`} className="track-image" />
                <div className="text-overlay">
                    <h3>{track.title}</h3>
                    <p>{track.boldText}</p>
                </div>
            </div>
      <div className="inner-box" style={{ padding: '20px', backgroundColor: 'white', borderRadius: '0 0 10px 10px' }}>
        {/* Additional content if needed */}
      </div>
    </div>
  ))}
</div>


    </div>
  </div>
</section>
  {/* Modal */}
  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>{modalContent.title}: {modalContent.boldText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: modalContent.content }}></div> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

            <section className="schedule-section">
                <div className="auto-container">
                    <div className="sec-title text-center">

                        <h2>Important Dates</h2>
                        <span className="divider" />
                    </div>   
 
                    <div class="container py-5">
                        <div class="main-timeline-4 text-white">
                            <div class="timeline-4 left-4">
                                <div class="card gradient-custom">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-brain fa-2x mb-3"></i> */}
                                        <h4> Paper submission</h4>
                                        <p   style={{ color: 'white'  }}>January 15th, 2025</p>

                                    </div>
                                </div>
                            </div>
                            <div class="timeline-4 right-4">
                                <div class="card gradient-custom-4">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-camera fa-2x mb-3"></i> */}
                                        <h4> Workshop proposal submission </h4>
                                        <p  style={{ color: 'white'  }}>January 15th, 2025</p>

                                    </div>
                                </div>
                            </div>
                            <div class="timeline-4 left-4">
                                <div class="card gradient-custom">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-campground fa-2x mb-3"></i> */}
                                        <h4>Notification of acceptance </h4>
                                        <p  style={{ color: 'white'  }}> March 31st, 2025</p>

                                    </div>
                                </div>
                            </div>
                            <div class="timeline-4 right-4">
                                <div class="card gradient-custom-4">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-camera fa-2x mb-3"></i> */}
                                        <h4>  Camera ready papers </h4>
                                        <p  style={{ color: 'white'  }}>April 30th, 2025</p>

                                    </div>
                                </div>
                            </div>

                            <div class="timeline-4 left-4">
                                <div class="card gradient-custom">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-campground fa-2x mb-3"></i> */}
                                        <h4> Early bird Author registration </h4>
                                        <p  style={{ color: 'white' }}> April 30th, 2025</p>

                                    </div>
                                </div>
                            </div>
                            <div class="timeline-4 right-4">
                                <div class="card gradient-custom-4">
                                    <div class="card-body p-4">
                                        {/* <i class="fas fa-camera fa-2x mb-3"></i> */}
                                        <h4> 	Conference dates </h4>
                                        <p style={{ color: 'white'  }}> June 4-6, 2025</p>

                                    </div>
                                </div>
                            </div> 


                        </div>
                    </div>

                </div>
            </section>
            {/*End schedule Section */}
            <div className="containero h-100">
  <div className="row align-items-center h-100">
    <div className="container rounded">
 
      <div className="slider">
        <div className="logos">
          <img src="images/clients/6.jpg" alt="JavaScript Logo" className="img-fluid" style={{maxHeight: '10rem', marginRight: '20rem',marginleft: '20rem'}} />
          <img src="images/clients/6.jpg" alt="LinkedIn Logo" className="img-fluid" style={{maxHeight: '10rem', marginRight: '20rem',marginleft: '20rem'}}  />
          <img src="images/clients/6.jpg" alt="Dribbble Logo" className="img-fluid" style={{maxHeight: '10rem', marginRight: '20rem',marginleft: '20rem'}} />
          <img src="images/clients/6.jpg" alt="Medium Logo" className="img-fluid" style={{maxHeight: '10rem', marginRight: '20rem',marginleft: '20rem'}} />
        
        </div>
        {/* <div className="logos">
          <img src="images/clients/6.jpg"alt="JavaScript Logo" className="img-fluid"style={{maxHeight: '5rem', marginRight: '15rem',marginleft: '15rem'}}  />
          <img src="images/clients/6.jpg" alt="LinkedIn Logo" className="img-fluid" style={{maxHeight: '5rem', marginRight: '15rem',marginleft: '15rem'}}  />
          <img src="images/clients/6.jpg" alt="Dribbble Logo" className="img-fluid" style={{maxHeight: '5rem', marginRight: '15rem',marginleft: '15rem'}}  />
          <img src="images/clients/6.jpg" alt="Medium Logo" className="img-fluid" style={{maxHeight: '5rem', marginRight: '15rem',marginleft: '15rem'}} />
          <img src="images/clients/6.jpg" alt="GitHub Logo" className="img-fluid" style={{maxHeight: '5rem', marginRight: '15rem',marginleft: '15rem'}} />
        </div> */}
      </div>
    </div>
  </div>
</div>


            {/* Call to Action */}
            <section className="call-to-action my-5" style={{ backgroundImage: 'url(images/background/1.jpg)' }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-dotted-world left" />
                   {/* <span className="icon icon-dotted-world right" />     */}
                </div>
                <div className="auto-container">
                    <div className="content-box wow fadeInUp" style={{ animationName: 'none' }}>
                        <h2>Proceedings</h2>
          <span classname="text" style={{textAlign: 'justify', color: 'white', fontSize: '1.2em'}}>
  All accepted papers will be included in the conference proceedings published under Springer’s Lecture Notes in Educational Technology (LNET) series (<a href="https://www.springer.com/series/11777" style={{color: 'white'}}>https://www.springer.com/series/11777</a>).
  <br /><br />
  Extended version of selected best accepted papers will be considered for publication in Springer’s Smart Learning Environments journal (<a href="https://slejournal.springeropen.com/" style={{color: 'white'}}>https://slejournal.springeropen.com/</a>).
</span>





                        {/* <a href="buy-ticket.html" className="theme-btn btn-style-two"><span className="btn-title">Book
                            Ticket</span></a> */}
                    </div>
                </div>
            </section>
            {/* End Call to Action */}
            {/*Clients Section*/}
            <section className="clients-section">
                <div className="auto-container">
                    <div className="row">
                        {/* Title Column */}
                        <div className="title-column col-xl-3 col-lg-4 col-md-12">
                            <div className="sec-title">
                                {/* <span className="sub-title">Clients</span> */}
                                <h2>  <br />Organizers</h2>
                                <div className="divider" />
                                {/* <div className="text">We have dedicated tracks for every industry Whether you want to hire
                                    tech’s top talent.</div> */}
                                {/* <a href="contact.html" className="theme-btn btn-style-one mt-4"><span className="btn-title">Buy
                                    Ticket</span></a> */}
                            </div>
                        </div>
                        {/* Title Column */}
                        <div className="title-column col-xl-9 col-lg-8 col-md-12">
                            <div className="sponsors-outer">
                                <div className="row">
                                    {/* Client Block */}
                                    <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" style={{ animationName: 'none' }}>
                                        <figure className="image-box"><a href="#"><img src="images/clients/1.png" alt /></a>
                                        </figure>
                                    </div>
                                    {/* Client Block */}
                                    <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="300ms" style={{ animationDelay: '300ms', animationName: 'none' }}>
                                        <figure className="image-box"><a href="#"><img src="images/clients/2.png" alt /></a>
                                        </figure>
                                    </div>
                                    {/* Client Block */}
                                    <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="600ms" style={{ animationDelay: '600ms', animationName: 'none' }}>
                                        <figure className="image-box"><a href="#"><img src="images/clients/3.png" alt /></a>
                                        </figure>
                                    </div>
                                    {/* Client Block */}
                                    <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="900ms" style={{ animationDelay: '900ms', animationName: 'none' }}>
                                        <figure className="image-box"><a href="#"><img src="images/clients/4.png" alt /></a>
                                        </figure>
                                    </div>
                                      {/* Client Block */}
                                      <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="900ms" style={{ animationDelay: '900ms', animationName: 'none' }}>
                                        <figure className="image-box"><a href="#"><img src="images/clients/5.png"  alt /></a>
                                        </figure>
                                    </div>

                                </div> 
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
         
            {/* <section className="clients-section  d-flex justify-content-center align-items-center">
    <div className="auto-container">
        <div className="row justify-content-center">
         
            <div className="title-column col-xl-3 col-lg-4 col-md-12 text-center">
                <div className="sec-title">
                    <h2>  <br />Sponsors</h2> 
                    <div className="divider mx-auto" />
                </div>
            </div>
         
            <div className="title-column col-xl-9 col-lg-8 col-md-12">
                <div className="sponsors-outer">
                    <div className="row justify-content-center">
        
                        <div className="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" style={{ animationName: 'none' }}>
                            <figure className="image-box">
                                <a href="#"><img src="images/clients/6.jpg" alt="Client" /></a>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}

        </div>




    )
}

export default Home