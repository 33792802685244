import React from 'react'
import Navbar from '../Layouts/Navbar'
import BarMobile from '../Layouts/BarMobile'


function Travel() {
    return (
        <>  <Navbar />
        <BarMobile />
 <section className="page-titlee" style={{ backgroundImage: 'url(images/background/val.jpg)' }}>
                
                    <div className="auto-container">
                        <div className="title-outer">
                            {/* <h1>Valenciennes</h1> */}
                           
                        </div>
                    </div>
                </section>
<section className="contact-section">
    <div className="auto-container">
        <div className="sec-title text-center">
            <span className="sub-title">Visiting Valenciennes</span>
            <h2>Getting to and around Valenciennes</h2>
            <span className="divider" />
        </div>
        <div className="row">
            <div className="col-md-4">
                <h3>Flying to Valenciennes</h3>
                <p
                style={{textAlign: 'justify',fontSize : '14px'}} >Flying to Valenciennes is straightforward, with the nearest major airport being Lille-Lesquin Airport, located about 40 minutes away by car. Lille-Lesquin offers a range of domestic and international flights, making it a convenient gateway for travelers from various locations. Alternatively, Paris Charles de Gaulle Airport is another option, situated approximately two hours away by train or car, providing extensive global connections.
                </p>
            </div>
            <div className="col-md-4">
                <h3>Airport Transfer</h3>
                <p
                style={{textAlign: 'justify',fontSize : '14px'}} >Airport transfer options from Lille-Lesquin to Valenciennes are plentiful and convenient. Travelers can choose from various transportation methods, including car rentals, taxis, and shuttle services. Additionally, there are frequent train services from Lille to Valenciennes, providing a quick and efficient way to reach the city center. For those arriving at Paris Charles de Gaulle, direct train services are available, offering a comfortable and scenic journey to Valenciennes.
                </p>
            </div>
            <div className="col-md-4">
                <h3>Getting Around</h3>
                <p
                style={{textAlign: 'justify',fontSize : '14px'}}  >  Getting around Valenciennes is easy and enjoyable, thanks to its efficient public transportation system and pedestrian-friendly layout. The city boasts a reliable network of buses and trams that connect key areas and attractions, ensuring smooth transit for visitors. Moreover, Valenciennes is compact and walkable, with many sites accessible on foot or by bicycle, allowing travelers to immerse themselves fully in the city's charm and vibrant atmosphere.
                </p>
            </div>
        </div>
    </div>
</section>

     {/* <section className="about-section">
    <div className="auto-container">
        <div className="row">
        
            <div className="content-column col-lg-5 col-md-12 col-sm-12 mr-3">
                <div className="inner-column">
                    <div className="sec-title">
                        <h2>About Valenciennes</h2>
                    
                    </div>
                    <p style={{textAlign: 'justify', color: 'black', fontSize : '14px'}}>
                        Valenciennes is a charming city located in the northern region of France, known for its rich cultural heritage and historical significance. Often referred to as the "Athens of the North," Valenciennes boasts a vibrant arts scene, with numerous museums, theaters, and galleries showcasing the city's deep-rooted artistic traditions. The city played a crucial role in the industrial revolution, becoming a key center for coal mining and steel production. Today, Valenciennes is celebrated for its beautiful architecture, including the stunning Basilica of Notre-Dame du Saint-Cordon and the picturesque Place d'Armes. With a blend of historical charm and modern amenities, Valenciennes offers a unique and inviting atmosphere for residents and visitors alike.
                    </p>
                </div>
            </div>
           
            <div className="col-lg-1 col-md-12 col-sm-12">
         
            </div>
            
            <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="about-image-wrapper">
                    <figure><img src="images/resource/val.jpg" alt /></figure>
                </div>
            </div>
        </div>
    </div>
</section> */}



<section className="about-section">
    <div className="auto-container">
        <div className="row">
            {/* Content Column */}
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                    <div className="sec-title">
                        <h2>Eating and drinking in Valenciennes</h2>
                    </div>
                    <p style={{textAlign: 'justify', color: 'black' ,fontSize : '14px'}}>
                        Eating and drinking in Valenciennes is a delightful experience that showcases the rich culinary heritage of northern France. The city offers a diverse array of dining options, from traditional brasseries and cozy bistros to upscale restaurants and trendy cafes. Local cuisine features hearty dishes like "carbonade flamande," a savory beef stew cooked in beer, and "potjevleesch," a terrine of mixed meats in gelatin. Cheese lovers will enjoy the regional specialty, "maroilles," known for its strong aroma and robust flavor. For those with a sweet tooth, the city's patisseries offer delectable treats like "tarte au sucre," a sweet pastry topped with butter and sugar. To complement these culinary delights, Valenciennes boasts a selection of fine wines, craft beers, and locally brewed ales. Whether you're savoring a leisurely meal in a charming café or indulging in a gourmet dinner, eating and drinking in Valenciennes is an experience that celebrates the region's gastronomic traditions and innovative spirit.
                    </p>
                </div>
            </div>
            {/* Spacer Column */}
            <div className="col-lg-1 col-md-12 col-sm-12">
                {/* Vous pouvez utiliser du CSS pour gérer l'espacement ici */}
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="about-image-wrapper">
                    <figure><img src="images/resource/eat.jpg" alt /></figure>
                </div>
            </div>
        </div>
    </div>
</section>



<section className="contact-section">
    <div className="auto-container">
        <div className="sec-title text-left">
            <h2>Attractions</h2>
            <span className="divider" />
        </div>
        <div className="row">
            <div className="col-md-4">
                <img src="images/resource/v1.jpg" alt="Flying to Valenciennes" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Valenciennes Museum of Fine Arts"</strong> Explore a diverse collection of artworks ranging from the Renaissance to the 20th century, including pieces by Rubens, Carpeaux, and Picasso.
                </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/v2.jpg" alt="Airport Transfer" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Grand Hornu" </strong> Step back in time at this UNESCO World Heritage Site, an iconic industrial complex turned cultural center featuring exhibitions, workshops, and events.
                </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/v3.jpg" alt="Getting Around" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Château de la Motte"</strong> Discover the rich history of Valenciennes at this stunning castle, which offers guided tours showcasing its medieval architecture and lush gardens.
                </p>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <img src="images/resource/v4.jpg" alt="Flying to Valenciennes" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Saint-Cordon Basilica"</strong> Marvel at the beauty of this historic basilica, known for its Gothic architecture and breathtaking stained glass windows, dating back to the 15th century.
                </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/v5.jpg" alt="Airport Transfer" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Museum of Contemporary Art"</strong> Immerse yourself in modern art at this dynamic museum, featuring exhibitions by both established and emerging artists from around the world.
                </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/v6.jpg" alt="Getting Around" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Parc de la Rhônelle"</strong> Relax and unwind in this picturesque park, complete with lush greenery, walking trails, and a charming lake perfect for a leisurely afternoon stroll.
                </p>
            </div> 
        </div>
    </div>
</section>

{/* hotell*/}
<section className="contact-section">
    <div className="auto-container">
        <div className="sec-title text-left">
            <h2>Hotels in Valenciennes</h2>
            <span className="divider" />
        </div>
        <div className="row">
            <div className="col-md-4">
                <img src="images/resource/h1.jpg" alt="Flying to Valenciennes" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Royal Hainaut Spa & Resort Hotel"</strong> Enjoy a luxurious stay in this historic 18th-century building turned modern hotel. It offers elegant rooms, a full-service spa, an indoor pool, and gourmet dining options. The combination of historical charm and contemporary amenities ensures a memorable experience.
                </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/h2.jpg" alt="Airport Transfer" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Mercure Valenciennes Centre Hotel" </strong> Centrally located, this hotel provides comfortable and stylish accommodations with modern amenities such as free Wi-Fi, a fitness center, and a bar. Its prime location makes it easy to explore nearby attractions, shopping, and dining options.  </p>
            </div>
            <div className="col-md-4">
                <img src="images/resource/h3.jpg" alt="Getting Around" className="attraction-image" />
                <p>
                    <strong style={{color: 'black'}}>"Hotel Le Chat Botté"</strong> A charming boutique hotel offering cozy, well-appointed rooms with a personal touch. Guests appreciate the warm hospitality, complimentary breakfast, and convenient location near the Valenciennes train station and major city sights. </p>
            </div>
        </div>
     
    </div>
</section>






















        </>
    )
}

export default Travel