import React from 'react'

function Footer() {
    return (
        <div className="main-footer">
            <div className="auto-container">
           
                <div className="footer-content wow fadeInUp">
                    <div className="text-center">
                    <span style={{ fontSize: "45px", fontWeight: "bold", color: "white" }}>ICMAIE 2025</span>

                    </div>


                </div>
            </div>
        </div> 



    )
}

export default Footer