
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Commite from './pages/Commite';
import Travel from './pages/Travel';
import Registration from './pages/Registration';
import Contact from './pages/Contact';
import Topbar from './Layouts/Topbar';
import Footer from './Layouts/Footer';
import Authors from './pages/Authors';
import ScrollToTopButton from './Layouts/Scroll';

function App() {
  return (
    <div className="App">
      

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/commite' element={<Commite />} />
        <Route path='/travel' element={<Travel />} />
        <Route path='/register' element={<Registration />} />
        <Route path='/authors' element={<Authors />} />
        <Route path='/contact' element={<Contact />} />


      </Routes>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default App;
